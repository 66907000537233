import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import React, { Fragment, PureComponent} from "react";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "axios";
import Spinner from "../../components/UI/Spinner/Spinner";
import Squares from "../../components/Squares/Squares";
import Banners from "./../../components/Banners/Banners";
import HomeBanner from "./HomeBanner/HomeBanner";
import ErrorDisplay from "./../../components/ErrorDisplay/ErrorDisplay";
import { Helmet } from "react-helmet";


class Home extends PureComponent {
  componentDidMount() {
    this.props.onFetchHome();
    this.props.onFetchSquares();
  }

  render() {
    //will render a loading icon if the data isn't loaded in yet
    let content = <Spinner />;
    let banners,
      squares,
      homeBanner = null;

    if (!this.props.loading && !this.props.home.loading) {
      if (!this.props.error && !this.props.home.error) {
        try {
          banners = (
            <Banners
              banners={this.props.home.banners["165"]}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              useKeyboardArrows={true}
              autoPlay={true}
              stopOnHover={true}
              interval={7000}
              transitionTime={350}
              auth={this.props.auth.auth}
            />
          );

          // productCarousel = (
          //   <ProductCarousel
          //     products={this.props.carousel}
          //     origin={this.props.origin}
          //     auth={this.props.auth.auth}
          //   />
          // );
          //Adding a note here to test pipline build
          homeBanner =  <HomeBanner  />

          // squares = !this.props.squares.loading && !this.props.squares.error ?  <Squares squares={[this.props.squares]}   auth={this.props.auth.auth} /> : null
          //
          // dealerSearchBlock = (< DealerSearchBlock />)
          //
          // testimonialPreview = (
          //   <TestimonialPreview testimonial={this.props.testimonial}   auth={this.props.auth.auth} />
          // );

          content = (
            <Fragment>
              {banners}
              {/*{productCarousel}*/}
              {homeBanner}
              {squares}
              {/*  {dealerSearchBlock}*/}

              {/*/!*<Suspense fallback={<div>Loading...</div>{squares}></Suspense>*!/*/}
              {/*{testimonialPreview}*/}
            </Fragment>
          );
        } catch (err) {
          content = <ErrorDisplay />;
        }
      } else {
        content = <ErrorDisplay />;
      }
    }

    return (
      <Fragment>
        <Helmet>
          <title>Home | Great Plains Trucking</title>
          <meta
            name="description"
            content="Great Plains Trucking is a leading producer of Grain Drills, and is also recognized across North America as a leader in Vertical Tillage, Hybrid Tillage, and Conventional Tillage Equipment, Planters, and Compact Drills."
          />
        </Helmet>
        {content}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
    origin: state.origin,
    squares: { ...state.squares },
    auth: { ...state.auth },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchHome: () => dispatch(actions.fetchHome()),
     onFetchSquares: () => dispatch(actions.fetchSquares()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Home, axios));
