import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

//Set the state to true to show hidden content or false to hide it. This checks to see if the user has a token.
//If you dont check this on refresh redux clears the state

//Check if the token is expired
let today = new Date();
let expire = new Date(sessionStorage.getItem("expirationDate"));
let booleanTest = today < expire;

//Check Jwt here
const initialState = {
  auth: sessionStorage.getItem("token") && booleanTest ? true : false,
  loading: true,
  error: false,
};

const fetchAuthStart = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchAuthSuccess = (state, action) => {
  return updateObject(state, {
    auth: action.token,
    loading: false,
    error: false,
  });
};

const fetchAuthLogout = (state, action) => {
  return updateObject(state, {
    auth: action.token,
    loading: false,
    error: false,
  });
};

const fetchAuthFail = (state) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_AUTH_START:
      return fetchAuthStart(state, action);
    case actionTypes.FETCH_AUTH_SUCCESS:
      return fetchAuthSuccess(state, action);
    case actionTypes.FETCH_AUTH_FAIL:
      return fetchAuthFail(state, action);
    case actionTypes.FETCH_AUTH_LOGOUT:
      return fetchAuthLogout(state, action);
    default:
      return state;
  }
};

export default reducer;
