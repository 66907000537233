import React from "react";
import classes from "./Spinner.module.css";
import Icon from "../../../assets/images/Icons/gear-solid.svg"
const Iconstyle = {
    maxHeight: "4rem",
    width: "4rem",
    position: "fixed",
    top: "40%",
    textIndent: "-9999em",
    zIndex: 1,
    padding: 0,
    left: "99%",
};

const HeaderStyle = {
    maxHeight: "7rem",
    width: "auto",
    position: "fixed",
    top:"45%",
    // left: "48%",
    zIndex: 1
}

const spinner = () => (

    <div className={classes.Wrapper}>
        <h3 style={HeaderStyle} className={classes.Loading}>Loading
            <span className={classes.LoadingSpan1}>&#8729;</span>
            <span className={classes.LoadingSpan2}>&#8729;</span>
            <span className={classes.LoadingSpan3}>&#8729;</span>
        </h3>
        <img style={Iconstyle} className={classes.Icon4} src={Icon} alt={"Cog Icon"}/>
        <img style={Iconstyle} className={classes.Icon3} src={Icon} alt={"Cog Icon"}/>
        <img style={Iconstyle} className={classes.Icon2} src={Icon} alt={"Cog Icon"}/>
       <img style={Iconstyle} className={classes.Icon} src={Icon} alt={"Cog Icon"}/>
   </div>

);

export default spinner;
