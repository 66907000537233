import * as actionTypes from "./actionTypes";
import axios from "axios";
import { getEnvironment } from "../../shared/utility";

export const fetchOrigin = () => {
  return (dispatch) => {
    dispatch(fetchOriginStart());
    const promises = [
      axios.get(`${getEnvironment()}/origin`),
      axios.get(`${getEnvironment()}/utility/nodeIndex`),
    ];

    const resolvedPromises = promises.map((promise) =>
      promise.catch((error) => ({ error }))
    );

    function checkFailed(then) {
      // console.log("TESTING here");
      return function (responses) {
        const someFailed = responses.some((response) => response.error);
        if (someFailed) {
          throw responses;
        }
        return then(responses);
      };
    }
    axios
      .all(resolvedPromises)
      .then(
        checkFailed(([origin, nodeIndex]) => {
          dispatch(
            fetchOriginSuccess({
              ...origin.data,
              ...nodeIndex.data,
            })
          );
        })
      )
      .catch((err) => {});
  };
};

export const fetchOriginStart = () => {
  return {
    type: actionTypes.FETCH_ORIGIN_START,
  };
};

export const fetchOriginSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_ORIGIN_SUCCESS,
  };
};

export const fetchOriginFail = (error) => {
  return {
    type: actionTypes.FETCH_ORIGIN_FAIL,
    error: error,
  };
};
