import * as actionTypes from "./actionTypes";
import axios from "../../axios-api";

export const fetchSquares = () => {
    return (dispatch) => {
        dispatch(fetchSquaresStart());
        axios
        .get("/squares")
        .then((res) => {
            dispatch(fetchSquaresSuccess(res));
        })
        .catch((err) => {
            dispatch(fetchSquaresFail(err));
        });
    };
};

export const fetchSquaresStart = () => {
    return {
        type: actionTypes.FETCH_SQUARES_START,
    };
};

export const fetchSquaresSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SQUARES_SUCCESS,
        squares: data,
    };
};

export const fetchSquaresFail = (error) => {
    return {
        type: actionTypes.FETCH_SQUARES_FAIL,
        error: error,
    };
};

