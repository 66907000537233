import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LinkSwitch from "./../../LinkSwitch/LinkSwitch";
import iconClasses from "../../Navigation/Icons.module.scss";
import classes from "./../SideDrawer.module.scss";
import { uuidv4 } from "../../../shared/utility";

class NavigationItems extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      this.props.navItems.forEach((navList) => {


        if (navList.purpose == this.props.type) {
          navItems = navList.languages["en"].links.map((element) => {
              if (element.showInHeader) {
                let linkClass = classes.Regular;
                if (element.icon != null) {
                  linkClass = `${classes.IconLink} ${
                    iconClasses[element.icon + "SideDrawer"]
                  }`;
                }
                let key = uuidv4();

                       return (
                           element.text != "Application" ?
                  <li key={key}   onClick={this.props.closed}>
                    <LinkSwitch
                      linkClass={linkClass}
                      text={element.text}
                      link={element.link}
                    />  </li> :
                      <li key={key} >
                        <a className={linkClass} onClick={() => this.props.showPopup()}>
                          {element.text}
                        </a>
                  </li>
                );
              }
            }
          );
        }
      });
    }
    return <Fragment> {navItems}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    navItems: state.nav.nav.navLinks,
    language: state.origin.language,
    loading: state.nav.loading,
  };
};

export default connect(mapStateToProps)(NavigationItems);
