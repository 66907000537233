import React from "react";
import Link from "react-router-dom/Link";
import classes from "./HomeBanner.module.scss";

 import productImageAVIF from "./LP22_GPT_1.jpg"

function HomeBanner() {
  return (

      <div className={`${classes.Wrapper} testGlobalClass`}>
      <div className={classes.Card}>
          <h3><span className={classes.CompanyName}>Welcome </span>to Great Plains Trucking</h3>
          <p>Since 1981, Great Plains Trucking has been a premier over-the-road, flatbed shipper, hauling loads throughout the continental United States and Canada. We employ only highly qualified drivers, invest in top-of-the-line tractors and trailers, and utilize state-of-the-art satellite communications and tracking equipment to keep tabs on every load. With Great Plains Trucking, you get not only competitive rates, but also an unparalleled on-time delivery and safety record that spans over 30 years.</p>
        <Link className={classes.Link} to="/279/">
          Learn More<span></span>
        </Link>
      </div>

      <div className={classes.Background}>
        <picture>
          <img
              className={classes.Image}
              src={productImageAVIF}
              alt={"Great Plains Trucking Home Banner photo."}
              loading="lazy"
          />
        </picture>
      </div>
    </div>
  );
}
export default React.memo(HomeBanner)
