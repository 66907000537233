import React, { Fragment } from "react";
import classes from "./NavigationItem.module.scss";
import LinkSwitch from "./../../../../../LinkSwitch/LinkSwitch";
let $iconClass;

function switcher(prop) {
  switch (prop) {
    case "home":
      $iconClass = classes.HomeIcon;
      break;
    case "current":
      $iconClass = classes.CurrentSiteIcon;
      break;
    case "lock":
      $iconClass = classes.LockIcon;
      break;
    case "about":
      $iconClass = classes.AboutIcon;
      break;
    case "careers":
      $iconClass = classes.CareerIcon;
      break;
      case "search":
      $iconClass = classes.SearchSiteIcon;
      break;
    default:
      $iconClass = null;
      break;
  }
}

const navigationItem = (props) => (
  <Fragment>
    {switcher(props.icon) && props.link}
    <li className={`${classes.NavigationItem}` + " " + `${$iconClass} Link`}>
      {props.link ? (
        <LinkSwitch link={props.link} text={props.children} />
      ) : (
        <a href={props.href ? props.href : null} target="_blank">
          {props.children}
        </a>
      )}
    </li>
  </Fragment>
);




export default navigationItem;
