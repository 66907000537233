import React from "react";
import classes from "./ErrorDisplay.module.scss";
import errorLogo from "./../../assets/images/components/Error/error_icon.png";

function refreshPage() {
  window.location.reload();
}

 function Error() {
  return (
    <div className={classes.Wrapper}>
      <img src={errorLogo} alt={"Error Icon"}/>
      <h1>Something Isn't Right Here!</h1>
      <p>The Great Plains Trucking web developers have been made aware of this issue.</p>
      <p>Please try refreshing the page.</p>
      <div>
        <button type="button" onClick={refreshPage}>
          Reload
        </button>
      </div>
    </div>
  );
}

export default  React.memo(Error)
