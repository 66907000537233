import React from "react";
import classes from "./Panel.module.scss";
import Link from "react-router-dom/Link";
import LearnMoreButton from "../../LearnMoreButton/LearnMoreButton";
import ConditionalWrapper from "../../../hoc/ConditionalWrapper/ConditionalWrapper";

export default React.memo(function Panel(props) {
  let imageUrl = props.data.field_banner_image;
  const CardAlignment = (position) => {
    switch (position) {
      case "left":
        return classes.HorizLeft;
      case "right":
        return classes.HorizRight;
      case "center":
        return classes.HorizRight;
      default:
        return classes.HorizRight;
    }
  };

  let bgImageStyle;

  bgImageStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: `${props.data.field_horizontal_alignment} ${props.data.field_vertical_alignment} `,
  };

  return (
      <ConditionalWrapper
          condition={props.auth}
          wrapper={(children) => (
              <div className={"HoverOver"}>
                <a
                    className={"EditThisLink"}
                    target="_blank"
                    href={`https://cms.gptrucking.com/node/${props.nid}/edit`}
                >
                  Edit This Content
                </a>
                {children}
              </div>
          )}
      >
        <div
            className={`${classes.Wrapper} ${
                classes.StandardWrapper
            } ${CardAlignment(props.data.field_caption_position)}`}
            style={bgImageStyle}
        >


          {props.data.image_only != true && (
              <div className={`${classes.Card} `}>
                { props.newProduct != undefined && props.newProduct != false ?
                    <div className={classes.NewProductBanner}>New</div> : null}
                <h3>{props.data.super_text}</h3>
               <h1>{props.data.banner_title}</h1>
                <div className={classes.FieldCaption} style={{ marginTop: "1rem", marginBottom: "1rem"  }}>

                  {props.data.field_caption_summary != undefined &&
                  props.data.field_caption_summary.length
                      ? props.data.field_caption_summary
                      : null}

                </div>


                  {/*{console.log(props.data.field_read_more_link)}*/}

                  {/*{props.data.field_read_more_link != null &&*/}
                  {/*props.data.field_read_more_link.length > 1 &&*/}
                  {/*    <div className={classes.ButtonWrapper}>*/}
                  {/*            <div className={classes.ButtonWrapper2}>*/}
                  {/*                    <Link to={`/${props.data.field_read_more_link}`}>*/}
                  {/*                        <button className={"Button"}>*/}
                  {/*                            { props.data.button_label ?  props.data.button_label  :"Learn More"}*/}
                  {/*                        </button>*/}

                  {/*                    </Link>*/}
                  {/*            </div>*/}
                  {/*    </div>*/}
                  {/*}*/}


                {props.data.field_read_more_link != null &&
                props.data.field_read_more_link.length > 0 &&
                !props.data.field_read_more_link.includes("http") ? (
                    <div className={classes.ButtonWrapper}>
                      {props.data.field_read_more_link != null && (
                          <div className={classes.ButtonWrapper2}>
                            {props.data.field_read_more_link.length > 1 &&
                            props.data.field_read_more_link.startsWith("http") ? (
                                <a
                                    target="_blank"
                                    href={`${props.data.field_read_more_link}`}
                                    className={"Link"}
                                >
                                    <button className={"Button"}>
                                        { props.data.button_label ?  props.data.button_label  :"Learn More"}
                                    </button>
                                </a>
                            ) : (
                                <Link to={props.data.field_read_more_link.startsWith("/") ? props.data.field_read_more_link : "/" + props.data.field_read_more_link }>
                                    <button className={"Button"}>
                                        { props.data.button_label ?  props.data.button_label  :"Learn More"}
                                    </button>

                                </Link>
                            )}
                          </div>
                      )}

                    </div>
                ) : (
                    <div className={classes.ButtonWrapper}>
                      {props.data.field_read_more_link != null && (
                          <LearnMoreButton
                              buttonLink={props.data.field_read_more_link.startsWith("/") ? props.data.field_read_more_link : "/" + props.data.field_read_more_link}
                          />
                      )}
                    </div>
                )}



              </div>
          )}
          {/*<img alt={props.data.super_text} loading={"lazy"} src={imageUrl} style={{*/}
          {/*  objectFit:"cover",*/}
          {/*  zIndex: "-1",*/}
          {/*  pointerEvents: "none",*/}
          {/*  position: "absolute",*/}
          {/*  width: "100%",*/}
          {/*  height: "100%",*/}
          {/*}}/>*/}

        </div>
      </ConditionalWrapper>
  );
});
