import React from "react";
import Square from "./Square/Square";
import classes from "./Squares.module.scss";

export default function Squares(props) {

  let fullCheck = [];
  let leftRight = [];
  props.squares.map((current, index) => {

    if (index == 0 || (index == props.squares.length - 1 && index % 2 != 0)) {
      fullCheck[index] = true;
    } else {
      if (index % 2 != 0) {
        leftRight[index] = "left";
      } else {
        leftRight[index] = "right";
      }
    }
  });

  return (
    <div className={classes.Wrapper}>
      {props.squares[0].squares.data.square.map((current, index) => (
        <Square
          key={current.id}
          square={current}
          key={index}
          side={leftRight[index]}
          full={fullCheck[index]}
          auth={props.auth}
        />
      ))}
    </div>
  );
}
