import React, {Fragment, useState} from "react";
import NavigationItems from "./NavigationItems/NavigationItems";
import classes from "./SideDrawer.module.scss";
import Link from "react-router-dom/Link";
import Popup from "../Popup/popup";



function sideDrawer(props){
  const [search, setSearch] = useState("");
  const [show, setshow] = useState(false);

  const onChange = (event) => {
    setSearch(event.target.value);
  };

  const updateInput = (e) => {
    setSearch("");
  };
  //Show popup if click on link
  const showPopup = () => {
    setshow(true)
  }

 const dontShowPopup = () => {
    setshow(false)

  }


  let attachedClasses = [classes.SideDrawer, classes.Close];
  let attachedClassesSecondary = [classes.ExtraContent, classes.Close];
  let attachedToggle = [classes.ToggleButton, classes.Right];

  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }

  if (props.moreOpen) {
    attachedClassesSecondary = [classes.ExtraContent, classes.Open];
    attachedToggle = [classes.ToggleButton, classes.Down];
  }

  const tertiaryClasses = `${classes.List}  ${classes.Tertiary}`;

  return (
    <Fragment>
      {/* <Backdrop show={props.open} clicked={props.closed} /> */}
      <div className={`${attachedClasses.join(" ")} Wrapper`}>

        <div>
          <Popup buttonText={"APPLICATION"}  show={show} dontShowPopup={dontShowPopup} />
            <div
                id={`HeaderSearchBlockMobile`}
                className={`${classes.HeaderSearchBlockMobile} HeaderSearchBlockMobile`}
            >
              <form className={`${classes.SearchFormMoblie} SearchFormMoblie`}>
                <label
                    className={`${classes.SearchLabelMoblie} SearchLabelMoblie`}
                    htmlFor={"Search"}
                >
                  Search
                </label>
                <input
                    className={`${classes.Search} Search`}
                    type="text"
                    name="Search"
                    placeholder="Search"
                    value={search}
                    onChange={onChange}
                    maxLength="256"
                />

                <Link
                    className={`${classes.ButtonAnchor} Link`}
                    to={`/search/${search}`}
                >
                  <input
                      name="serach"
                      onSubmit={updateInput}
                      type="submit"
                      value="Search"
                      className={`${classes.SubmitButton} SubmitButton`}
                      placeholder=""
                      onClick={props.closed}
                  />
                </Link>
              </form>
            </div>
          <ul className={`${classes.List} List`} onClick={props.closed}>
            <NavigationItems type={"primary"} closed={props.closed} />
          </ul>

          <button
            className={`${attachedToggle.join(" ")} Toggle`}
            onClick={props.moreToggle}
          >
            {props.moreOpen ? "Less" : "More"}
          </button>
          <ul
            className={`${attachedClassesSecondary.join(" ")} List`}
            onClick={props.closed}
          >
            <NavigationItems type={"secondary"}  />
          </ul>
          <ul className={tertiaryClasses}  >

            <NavigationItems type={"tertiary"} closed={props.closed} showPopup={showPopup}/>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

export default sideDrawer;
