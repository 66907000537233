import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions";
import loadable from "@loadable/component";
import Layout from "./hoc/Layout/Layout";
import Home from "./containers/Home/Home";
import Spinner from "./components/UI/Spinner/Spinner";
const Node = loadable(() => import("./containers/Node/Node"));
const Article = loadable(() => import("./containers/Article/Article"));
const Search = loadable(() => import("./containers/Search/Search"));
const CompanyWebsites = loadable(() =>
    import("./components/CompanyWebsites/CompanyWebsites")
);
const Login = loadable(() => import("./components/Auth/Login/Login"));
const Logout = loadable(() => import("./components/Auth/Logout/Logout"));


class App extends React.PureComponent {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    this.props.onFetchNav();
    this.props.onFetchOrigin();
  }




  render() {
    //always available
    let publicPaths;

    let nodeMap = [];
    if (!this.props.origin.loading) {
      for (const key in this.props.origin.nodeMap) {
        let temp = this.props.origin.nodeMap[key];
        nodeMap.push(<Route key="node" path="/node/:nid/" component={Node} />);
      }

      publicPaths = [
        ...nodeMap,
        <Route key="index-no-lang" path={`/`} exact component={Home} />,
        <Route
            key="company-Websites"
            exact
            path="/company-websites"
            component={CompanyWebsites}
        />,
        <Route key="search" path="/search/:query?" component={Search} />,
        <Route key={"article"} path={`/:nid/:title`} component={Article} />,
        <Route
            key={"article-from-node"}
            path={`/:nid/:title?`}
            component={Node}
        />,

        <Route key="notFound" component={Node} />,
      ];
    }

    //these are here for if/when users can log in through the site. could use free firebase auth plan with the company gsuite so handle logins very  easy.
    //these are only available when logged in.
    let protectedPaths = [
      <Route key="Logout" path={`/:language?/Logout`} component={Logout} />,
      <Route key="Login" path={`/users`} component={Logout} />,
      <Route key="Login" path={`/login`} component={Logout} />,
      <Route key="Login" path={`/user`} component={Logout} />,
    ];

    //only available when not logged in. We dont want to show login if you are logged in
    let unprotectedPaths = [
      <Route key="Login" path={`/:language?/users`} component={Login} />,
      <Route key="Login" path={`/login`} component={Login} />,
      <Route key="Login" path={`/user`} component={Login} />,
      <Route key="Logout" path={`/Logout`} component={Login} />,
    ];

    // Check if the token is expired
    let today = new Date();
    let expire = new Date(sessionStorage.getItem("expirationDate"));
    let booleanTest = today < expire;

    let allowedPaths = [].concat(publicPaths);
    if (this.props.origin.VPN || process.env.NODE_ENV == "development") {
      allowedPaths = (sessionStorage.getItem("token") && booleanTest
              ? protectedPaths
              : unprotectedPaths
      ).concat(publicPaths);
    }

    let paths = allowedPaths.map((element, index) => element);

    let myRouter = this.props.origin.loading ? (
        <Spinner />
    ) : (
        <Layout>
          <Switch>{paths}</Switch>
        </Layout>
    );
    return myRouter;
  }
}
const mapStateToProps = (state) => {
  return {
    origin: { ...state.origin },
    auth: { ...state.auth },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchNav: () => dispatch(actions.fetchNav()),
    onFetchOrigin: () => dispatch(actions.fetchOrigin()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
