import "core-js/stable";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "core-js/features/array/includes";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import Link from "react-router-dom/Link";
import "./index.scss";
import App from "./App";
import navReducer from "./store/reducers/nav";
import originReducer from "./store/reducers/origin";
import homeReducer from "./store/reducers/home";
import articlesReducer from "./store/reducers/articles";
import bannersReducer from "./store/reducers/banners";
import searchReducer from "./store/reducers/search";
import squaresReducer from "./store/reducers/squares";
import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";
import BrowserChecker from "./hoc/BrowserCheck/BrowserChecker";
import authReducer from "./store/reducers/auth";

console.warn = () => {};
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

const rootReducer = combineReducers({
  home: homeReducer,
  nav: navReducer,
  origin: originReducer,
  articles: articlesReducer,
  banners: bannersReducer,
  search: searchReducer,
  auth: authReducer,
  squares: squaresReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserChecker debug={false} />
    <BrowserRouter>
      <Fragment>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Fragment>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
// registerServiceWorker();
