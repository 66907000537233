import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Panel from "./Panel/Panel";
import Parser from "html-react-parser";

function Banner(props) {
  let banner = props.banners["335"] ? props.banners["335"] : props.banners
  let multipleSlides = banner != undefined && banner.length == 1 ? false : true;
  return (
    <Carousel
      showIndicators={multipleSlides}
      swipeScrollTolerance={12}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      useKeyboardArrows={true}
      autoPlay={true}
      stopOnHover={true}
      swipeable={false}
      interval={7000}
      transitionTime={350}
    >
      {banner.sort(function (a, b){
        //Some products dont have weight so we give them a neutral weight
        if(a.weight === undefined ){
          a.weight = 0
        }
        if(b.weight === undefined ){
          b.weight = 0
        }
        //Return the product based on weight
        return a.weight - b.weight
      }).map((current) => (
          <Panel
            key={current.nid}
            data={{
              isProduct: false,
              field_caption_position: current.caption_alignment.value ? current.caption_alignment.value : "right",
              field_vertical_alignment: current.vertical_alignment.value ?  current.vertical_alignment.value : "right",
              field_horizontal_alignment: current.horizontal_alignment.value ? current.horizontal_alignment.value : "right",
              field_banner_image: current.image.values[0] != undefined && current.image.values[0].images != undefined && current.image.values[0].images["2600px_uri"] != undefined ? current.image.values[0].images["2600px_uri"] : [],
              field_read_more_link: current.read_more && current.read_more.url  ? current.read_more.url : null,
              button_label: current.read_more && current.read_more.label  ? current.read_more.label : null,
              banner_title: Parser(
                  current.banner_title.value
              ),
              field_caption_summary: Parser(
                  current.caption.value
              ),
            }}
            mobile={current.image.values[0].images}
            desktop={current.image.values[0].images}
            noSpan={true}
            auth={props.auth}
               nid={current.nid}
          />
        ))}
    </Carousel>
  );
}

export default  React.memo(Banner)
