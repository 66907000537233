import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./NavigationItems.module.scss";
import NavigationItem from "./NavigationItem/NavigationItem";
import Popup from "../../../../Popup/popup"

class navigationItems extends Component {
  constructor(props) {
    super(props);
    //we Add a ref so we can close the box info box if you click outside it

    this.state = {
      show: false,

    }
    this.dontShowPopup = this.dontShowPopup.bind(this)
    this.showPopup = this.showPopup.bind(this)
  }

  //Show popup if click on link
  showPopup(item){
    this.setState({show: true})

  }

  dontShowPopup(){
    this.setState({show: false})

  }



  render() {
    let showpopup = this.showPopup
    //will render a loading icon if the data isn't loaded in yet
    let navItems = <div>loading</div>;
    if (!this.props.loading) {
      let current = this.props.nav[2].languages["en"].links;
      if (current == null || current == undefined) {
        current = this.props.nav[2].languages["en"].links;
      }

      navItems = current.map(function (item, index){
        if(item.text != "Application"){
        return (
        <NavigationItem
          key={index}
          link={item.link}
          icon={item.link == "/search" ? null : item.icon}
          exact
        >
          {item.text}
        </NavigationItem>
      )} else return  <NavigationItem
            key={index}
            // link={item.link}
            icon={item.icon}
            exact
        ><div onClick={(() => showpopup(item))}>
          {item.text}
        </div>
        </NavigationItem>
      }
      )
    }
    return (
        <>
      <div className={`${classes.MiscNavRibbon} MiscNavRibbon`}>
        <ul className={`${classes.NavigationItems} NavigationItems`}>
          {navItems}
        </ul>

      </div>
        <Popup buttonText={"APPLICATION"}  show={this.state.show} dontShowPopup={this.dontShowPopup} />
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nav: state.nav.nav.navLinks,
    language: state.origin.language,
    loading: state.nav.loading,
  };
};

export default connect(mapStateToProps)(navigationItems);
