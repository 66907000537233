import React, {Component} from "react";
import classes from "./popup.module.scss"

export default class Popup extends Component {
    constructor(props) {
        super(props);
        //we Add a ref so we can close the box info box if you click outside it
        this.wrapperRef = React.createRef();
        this.state = {
            show: false,
            item: "",

        }

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.props.show)

    }

    componentDidMount() {
            document.addEventListener("mousedown", this.handleClickOutside);
        }

        componentWillUnmount() {
            document.removeEventListener("mousedown", this.handleClickOutside);
        }
    //Remove popup if you click off it
    handleClickOutside(event){
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.props.dontShowPopup()
        }
    }

        render(){
            return (
                <>{this.props.show == true ?
                <div className={`${classes.TellUsersAboutRedirect}`} ref={this.wrapperRef}>

                    <div className={`${classes.DivTagColor}`}>

                 <span className={`${classes.Span}`}>
               <img
                   src={
                       "https://cdn-assets.greatplainsmfg.com/shared_assets/greatplainsag/images/info.png"
                   }
                   className={`${classes.IconForMessage}`}
                   alt={"Info Icon"}
               />
               <h6 className={`${classes.H5}`}>Information</h6>
                   </span>

                        <p className={`${classes.TextInInfoBlock}`}>
                            The link "{this.props.buttonText}" links to external sources
                            "apply.driverreachapp.com". This website collects user inputted information for the purpose
                            of employment considerations and eligibility for Great Plains Trucking.
                        </p>
                        <div className={`${classes.Buttons}`}>
                            <a href="https://apply.driverreachapp.com/universal_app/2qL0DJSY9WSadq6_mOwDnQ/l/347840/short?ls=12997&type=dot_app" target="_blank"> <button className={`${classes.ButtonSingle}`}>Continue
                            </button></a>
                            <button onClick={() => {  this.props.dontShowPopup()}} className={`${classes.ButtonSingle}`}>Close</button>
                        </div>

                    </div>

                </div> : null}
                </>
            );

    }
}
