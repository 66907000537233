import * as actionTypes from "./actionTypes";
import axios from "./../../axios-api";


export const fetchArticles = () => {
  return (dispatch) => {
    dispatch(fetchArticlesStart());
    axios
      .get("/articles")
      .then((response) => {
        // console.log("got the response", response);
        //
        // console.log("got the response data", response.data);
        // console.log("got the response data sections", response.data.sections);
        dispatch(fetchArticlesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchArticlesFail(err));
      });
  };
};


export const fetchSingleArticle = (nId) => {
  return (dispatch) => {
    dispatch(fetchLandProductStart());
    axios
    .get(`/articles/${nId}`)
    .then((response) => {
      dispatch(fetchArticlesSuccessSingle(response.data));
    })
    .catch(() => {});
  };
};


/***********************************************************
// Fetch All Manuals
***********************************************************/
export const fetchArticlesStart = () => {
  return {
    type: actionTypes.FETCH_ARTICLES_START,
  };
};

export const fetchArticlesSuccess = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_ARTICLES_SUCCESS,
  };
};
export const fetchArticlesSuccessSingle = (data) => {
  return {
    data: data,
    type: actionTypes.FETCH_ARTICLES_SUCCESS_SINGLE,
  };
};

export const fetchArticlesFail = (error) => {
  return {
    type: actionTypes.FETCH_ARTICLES_FAIL,
    error: error,
  };
};
