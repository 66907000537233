import React from "react";
import classes from "./AdminBar.module.scss";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
class AdminBar extends React.PureComponent {

  render() {
    return (
      <nav className={classes.AdminBarWrapper}>
        <ul className={classes.ListWrapper}>
          <li>
            {/*<CacheDropDown />*/}
          </li>
          <li>
            <a
              href="https://cms.landpride.com/admin/content-tracking"
              target="_blank"
            >
              Content Tracking
            </a>
          </li>
          <li onClick={() => this.props.onfetchAuthLogout()}>Logout &times;</li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: { ...state.auth },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchAuthLogout: () => dispatch(actions.fetchAuthLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBar);
