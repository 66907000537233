import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    isEmpty: true,
    loading: true,
};

const fetchSquaresStart = (state) => {
    return updateObject(state, {
        loading: state.isEmpty,
    });
};

const fetchSquaresSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        loading: false,
        isEmpty: false,
        error: false,
    });
};

const fetchSquaresFail = (state) => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SQUARES_START:
            return fetchSquaresStart(state, action);
        case actionTypes.FETCH_SQUARES_SUCCESS:
            return fetchSquaresSuccess(state, action);
        case actionTypes.FETCH_SQUARES_FAIL:
            return fetchSquaresFail(state, action);
        default:
            return state;
    }
};

export default reducer;
