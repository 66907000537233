import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  isEmpty: true,
  loading: true,
};

const fetchHomeStart = (state) => {
  return updateObject(state, {
    loading: state.isEmpty,
  });
};

const fetchHomeSuccess = (state, action) => {
  return updateObject(state, {
    ...action.home,
    loading: false,
    isEmpty: false,
    error: false,
  });
};

const fetchHomeFail = (state) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_HOME_START:
      return fetchHomeStart(state, action);
    case actionTypes.FETCH_HOME_SUCCESS:
      return fetchHomeSuccess(state, action);
    case actionTypes.FETCH_HOME_FAIL:
      return fetchHomeFail(state, action);
    default:
      return state;
  }
};

export default reducer;
