import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import classes from "./Layout.module.scss";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import AlertAtTheTopOfThePage from "../AlertAtTheTopOfThePage/AlertAtTheTopOfThePage";
const Footer = lazy(() => import("../../components/Footer/Footer"));


class Layout extends Component {
  state = {
    showSideDrawer: false,
    showMore: false,
  };

  removeOnResize = () => {
    if (window.innerWidth >= 979 && this.state.showSideDrawer) {
      this.setState({
        showSideDrawer: false,
        showMore: false,
      });
    }
  };

  componentDidMount() {
    this.removeOnResize();
    window.addEventListener("resize", this.removeOnResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.removeOnResize.bind(this));
  }

  sideDrawerClosedHandler = () => {
    this.setState({
      showSideDrawer: false,
      showMore: false,
    });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return {
        showSideDrawer: !prevState.showSideDrawer,
        showMore: false,
      };
    });
  };

  showMoreToggleHandler = () => {
    this.setState((prevState) => {
      return { showMore: !prevState.showMore };
    });
  };

  render() {
    return (
      <div className={`${classes.Container} Container`} key="Layout">
        <Toolbar
          drawerToggleClicked={this.sideDrawerToggleHandler}
          closed={this.sideDrawerClosedHandler}
          open={this.state.showSideDrawer}
        />

        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
          moreOpen={this.state.showMore}
          moreToggle={this.showMoreToggleHandler}
          key={"SideDrawer"}
        />
        <AlertAtTheTopOfThePage isOn={false} />
        <main
          onClick={this.sideDrawerClosedHandler}
          className={`${classes.Content} Content`}
          key={"MAin"}
        >
          {this.props.children}
        </main>

        <Suspense fallback={<div>Loading...</div>}>
          {/*<Footlight social={this.props.social} key={"FootLight"} />*/}
          <Footer closed={this.sideDrawerClosedHandler} key={"Footer"} />
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Layout);
