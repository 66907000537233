import * as actionTypes from "./actionTypes";

import { getEnvironment } from "../../shared/utility";
let apiUrl = getEnvironment();

export const fetchAuth = (data) => {
  const bodyData = new URLSearchParams();
  Object.entries(data).map(function (a) {
    bodyData.append(a[0], a[1]);
  });

  return (dispatch) => {
    dispatch(fetchAuthStart());
    fetch(
      `${apiUrl}/42rt4JP/9ZXZEX3mZpCNByww`,
      { method: "POST", body: bodyData },
      { mode: "no-cors" }
    )
      .then((res) => res.json())
      .then((data) => {
        dispatch(fetchAuthSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchAuthFail(err));
      });
  };
};

/***********************************************************
 // Fetch All Products
 ***********************************************************/
export const fetchAuthStart = () => {
  return {
    type: actionTypes.FETCH_AUTH_START,
  };
};

export const fetchAuthSuccess = (data) => {
  data.token != undefined || data.token != null
    ? sessionStorage.setItem("token", JSON.stringify(data.token))
    : null;
  const expirationDate = new Date(
    new Date().getTime() + data.expires_in * 1000
  );

  data.expires_in != undefined || data.expires_in != null
    ? sessionStorage.setItem("expirationDate", expirationDate)
    : null;

  return {
    token: data.token != undefined || data.token != null ? true : false,
    type: actionTypes.FETCH_AUTH_SUCCESS,
  };
};

export const fetchAuthLogout = () => {
  console.log("HIT")
  sessionStorage.clear();
  return {
    token: false,
    type: actionTypes.FETCH_AUTH_LOGOUT,
  };
};

export const fetchAuthFail = (error) => {
  return {
    type: actionTypes.FETCH_AUTH_FAIL,
    error: error,
  };
};
