export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getEnvironment = () =>
  //  "api.gptrucking.com"
  process.env.NODE_ENV != "development" ? "https://" + "api-gptrucking.gpmfg.io" + "/v1" : "http://localhost:8000/v1";
