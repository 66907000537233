/***********************************************************
 //Auth
 ***********************************************************/
export const FETCH_AUTH_START = "FETCH_AUTH_START";
export const FETCH_AUTH_SUCCESS = "FETCH_AUTH_SUCCESS";
export const FETCH_AUTH_FAIL = "FETCH_AUTH_FAIL";
export const FETCH_AUTH_LOGOUT = "FETCH_AUTH_LOGOUT";


/***********************************************************
//NAV_START = 'FETCH_NAV_START';
***********************************************************/
export const FETCH_NAV_START = "FETCH_NAV_START";
export const FETCH_NAV_SUCCESS = "FETCH_NAV_SUCCESS";
export const FETCH_NAV_FAIL = "FETCH_NAV_FAIL";
export const NAV = "NAV";

/***********************************************************
//articles
***********************************************************/
export const FETCH_ARTICLES_START = "FETCH_ARTICLES_START";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_SUCCESS_SINGLE = "FETCH_ARTICLES_SUCCESS_SINGLE";
export const FETCH_ARTICLES_FAIL = "FETCH_ARTICLES_FAIL";

/***********************************************************
//banners
***********************************************************/
export const FETCH_BANNERS_START = "FETCH_BANNERS_START";
export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";
export const FETCH_BANNERS_FAIL = "FETCH_BANNERS_FAIL";

/***********************************************************
 //search
 ***********************************************************/
export const FETCH_SEARCH_START = "FETCH_SEARCH_START";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const FETCH_SEARCH_FAIL = "FETCH_SEARCH_FAIL";

/***********************************************************
 //home
 ***********************************************************/
export const FETCH_HOME_START = "FETCH_HOME_START";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAIL = "FETCH_HOME_FAIL";

/***********************************************************
 //Squares
 ***********************************************************/
export const FETCH_SQUARES_START = "FETCH_SQUARES_START";
export const FETCH_SQUARES_SUCCESS = "FETCH_SQUARES_SUCCESS";
export const FETCH_SQUARES_FAIL = "FETCH_SQUARES_FAIL";

/***********************************************************
 //origin
 ***********************************************************/
export const FETCH_ORIGIN_START = "FETCH_ORIGIN_START";
export const FETCH_ORIGIN_SUCCESS = "FETCH_ORIGIN_SUCCESS";
export const FETCH_ORIGIN_FAIL = "FETCH_ORIGIN_FAIL";



